import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GeneralSettingsService } from './general-settings.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {

    logoUrl: string = '';
    privacyPolicyHtml: string;
    termsOfServiceHtml: string;

    /**
     * Constructor
     */
    constructor(private _generalSettingsService: GeneralSettingsService) {
    }

    ngOnInit(): void {
        this._generalSettingsService.getBrandingSettings().subscribe(response => {

            if (response) {
                if (response.APP_BRANDING) {
                    const branding = response.APP_BRANDING;
                    localStorage.setItem('brandingLogo', branding.favicon || '');

                    const splashLogoElement = document.getElementById('splash-logo');
                    if (splashLogoElement && branding.favicon) {
                        splashLogoElement.setAttribute('src', branding.favicon);
                    }

                    this._generalSettingsService.setLogo(branding?.logo);
                    this._generalSettingsService.setHeaderBackgroundColor(branding?.headerBackgroundColor);
                    this._generalSettingsService.setHeaderColor(branding?.headerColor);
                    this._generalSettingsService.setLeftPanelColor(branding?.leftPanelColor);
                    this._generalSettingsService.setLeftPanelIconColor(branding?.leftPanelIconColor);
                    this._generalSettingsService.setDialogHeaderColor(branding?.dialogsHeaderColor);
                    this._generalSettingsService.setDialogColor(branding?.dialogsColor);
                    this._generalSettingsService.setPrimaryBackgroundColor(branding?.primaryBackgroundColor);
                    this._generalSettingsService.setSecondaryBackgroundColor(branding?.secondaryBackgroundColor);
                    this._generalSettingsService.setPrimaryColor(branding?.primaryColor);
                    this._generalSettingsService.setSecondaryColor(branding?.secondaryColor);
                    this._generalSettingsService.setOtherBackgroundColor(branding?.otherBackgroundColor);
                    this._generalSettingsService.setOtherColor(branding?.otherColor);
                } else {
                    const branding = response;
                    localStorage.setItem('brandingLogo', branding?.favicon || '');

                    const splashLogoElement = document.getElementById('splash-logo');
                    if (splashLogoElement && branding?.favicon) {
                        splashLogoElement.setAttribute('src', branding?.favicon);
                    }

                    this._generalSettingsService.setLogo(branding?.logo);
                    this._generalSettingsService.setHeaderBackgroundColor(branding?.headerBackgroundColor);
                    this._generalSettingsService.setHeaderColor(branding?.headerColor);
                    this._generalSettingsService.setLeftPanelColor(branding?.leftPanelColor);
                    this._generalSettingsService.setLeftPanelIconColor(branding?.leftPanelIconColor);
                    this._generalSettingsService.setDialogHeaderColor(branding?.dialogsHeaderColor);
                    this._generalSettingsService.setDialogColor(branding?.dialogsColor);
                    this._generalSettingsService.setPrimaryBackgroundColor(branding?.primaryBackgroundColor);
                    this._generalSettingsService.setSecondaryBackgroundColor(branding?.secondaryBackgroundColor);
                    this._generalSettingsService.setPrimaryColor(branding?.primaryColor);
                    this._generalSettingsService.setSecondaryColor(branding?.secondaryColor);
                    this._generalSettingsService.setOtherBackgroundColor(branding?.otherBackgroundColor);
                    this._generalSettingsService.setOtherColor(branding?.otherColor);
                }
            }

        });

        this.getAppName();
        this.getTermsOfService();
        this.getPrivacyPolicy();
    }

    getTermsOfService() {

        this._generalSettingsService.getTermsSettings().subscribe(response => {

            if (response) {
                this.termsOfServiceHtml = response;
                this._generalSettingsService.setTermsSettings(response);
            }

        });
    }

    getPrivacyPolicy() {

        this._generalSettingsService.getPrivacyPolicySettings().subscribe(response => {

            if (response) {
                this.privacyPolicyHtml = response;
                this._generalSettingsService.setPrivacyPolicy(response);
            }

        });
    }

    getAppName() {
        this._generalSettingsService.getAppName().subscribe(response => {
            if (response) {
                this._generalSettingsService.setAppName(response);
                localStorage.setItem('appName', response || '');
            }
        });
    }
}
