/* eslint-disable */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: "documents",
        title: "documents",
        subtitle: "manage-your-documents",
        type: "group",
        // meta: "simple_user",
        children: [
            {
                id: "docs",
                title: "all-documents",
                type: "basic",
                icon: "feather:inbox",
                link: "/documents/all-documents"
            },
            {
                id: "pages.coming-soon.classic",
                title: "Draft",
                type: "basic",
                icon: "heroicons_outline:document",
                link: "/documents/draft-documents"
            },
            {
                id: "pages.coming-soon.classic",
                title: "Pending",
                type: "basic",
                icon: "heroicons_outline:clock",
                link: "/documents/pending-documents"
            },
            {
                id: "pages.coming-soon.classic",
                title: "sent",
                icon: "feather:send",
                type: "basic",
                link: "/documents/sent-documents"
            },
            // {
            //     id: "pages.coming-soon.classic",
            //     title: "Signed",
            //     icon: "heroicons_outline:pencil-square",
            //     type: "basic",
            //     link: "/documents/signed-documents"
            // },
            {
                id: "pages.coming-soon.classic",
                title: "Completed",
                icon: "feather:check-circle",
                type: "basic",
                link: "/documents/complete-documents"
            },
            {
                id: "pages.coming-soon.classic",
                title: "Void",
                icon: "feather:x-circle",
                type: "basic",
                link: "/documents/void-documents"
            },
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: "example",
        title: "Example",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/example"
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: "example",
        title: "Example",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/example"
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: "example",
        title: "Example",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/example"
    }
];
