import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: 'example' },
    { path: '', pathMatch: 'full', redirectTo: 'documents' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.

    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'documents' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
            {
                path: 'privacy-policy',
                loadChildren: () =>
                    import('app/modules/auth/privacy-policy/privacy-policy.routes'),
            },
            {
                path: 'terms-of-services',
                loadChildren: () =>
                    import('app/modules/auth/terms-of-services/terms-of-services.routes'),
            },
            {
                path: 'verified-user',
                loadChildren: () =>
                    import('app/modules/auth/verified-user/verified-user.component.routes'),
            },
            {
                path: 'link-expired',
                loadChildren: () =>
                    import('app/modules/auth/link-expired/verified-user.component.routes'),
            },
            {
                path: 'redirect-qr',
                loadChildren: () =>
                    import(
                        'app/modules/auth/redirect-qr/redirect-qr.routes'
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
        ],
    },

    // CSC redirect Page routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'redirects/csc2',
                loadChildren: () => import('app/modules/csc-signing/csc-redirect-page/csc-redirect-page.routes'),
            }
        ],
    },

    // Wait Page routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'wait',
                loadChildren: () =>
                    import('app/modules/document/wait-document/wait-document.routes'),
            }
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.routes'),
            },
        ],
    },

    // Doc Decline Screen routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'void',
                loadChildren: () =>
                    import('app/modules/auth/decline-screen/decline-screen.routes'),
            }
        ],
    },

    // temp routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'temp',
                loadChildren: () => import('app/modules/temp-page/temp.routes'),
            },
        ],
    },

    // document routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'documents',
                loadChildren: () =>
                    import('app/modules/document/document.routes'),
            },
        ],
    },

    // Settings routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'settings',
                loadChildren: () =>
                    import('app/modules/settings/settings.routes'),
            },
            {
                path: 'adminsettings',
                loadChildren: () =>
                    import(
                        'app/modules/administrationSettings/administrationSettings.routes'
                    ),
            },
            {
                path: 'app-detail',
                loadChildren: () => import('app/modules/business-app-details/business-app-details.routes'),
            },
        ],
    },

    // Error routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: '',
                children: [
                    {
                        path: '400',
                        loadChildren: () =>
                            import(
                                'app/modules/error/error-400/error-400.routes'
                            ),
                    },
                    {
                        path: '401',
                        loadChildren: () =>
                            import(
                                'app/modules/error/error-401/error-401.routes'
                            ),
                    },
                    {
                        path: '403',
                        loadChildren: () =>
                            import(
                                'app/modules/error/error-403/error-403.routes'
                            ),
                    },
                    {
                        path: '404',
                        loadChildren: () =>
                            import(
                                'app/modules/error/error-404/error-404.routes'
                            ),
                    },
                    {
                        path: '500',
                        loadChildren: () =>
                            import(
                                'app/modules/error/error-500/error-500.routes'
                            ),
                    },
                ],
            },
            { path: '**', redirectTo: '/404' },
        ],
    },
];
