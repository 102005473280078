import { CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { GeneralSettingsService } from 'app/general-settings.service';

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatFormField,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        NgClass,
        CommonModule
    ],
})
export class FuseConfirmationDialogComponent implements OnInit {

    dialogColor: any;

    primaryBackgroundColor: any;
    secondaryBackgroundColor: any;

    primaryColor: any;
    secondaryColor: any;

    dialogForm: FormGroup;

    /**
     * Constructor
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmationDialogComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig,
        private _generalSettingService: GeneralSettingsService,
    ) {
        this.dialogForm = this._formBuilder.group({
            title: [data.title],
            message: [data.message],
            actions: this._formBuilder.group(data.actions)
        });
    }
    ngOnInit(): void {

        this._generalSettingService.dialogColorSubject$.subscribe(dialogColorSubject => {
            this.dialogColor = dialogColorSubject;
        });

        this._generalSettingService.primaryBackgroundColorSubject$.subscribe(primaryBackgroundColorSubject => {
            this.primaryBackgroundColor = primaryBackgroundColorSubject;
        });

        this._generalSettingService.secondaryBackgroundColorSubject$.subscribe(secondaryBackgroundColor => {
            this.secondaryBackgroundColor = secondaryBackgroundColor;
        });

        this._generalSettingService.primaryColorSubject$.subscribe(primaryColorSubject => {
            this.primaryColor = primaryColorSubject;
        });

        this._generalSettingService.secondaryColorSubject$.subscribe(secondaryColorSubject => {
            this.secondaryColor = secondaryColorSubject;
        });
    }

}
